@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('Roboto-Light.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('Roboto-Regular.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('Roboto-Bold.ttf');
}

@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 800;
  font-display: swap;
  src: url('Roboto-Black.ttf');
}
