.contacts__content {
  max-width: 1140px;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
  align-items: center;
  gap:20px
}
@media screen and (max-width: 950px) {
  .contacts__content {
    margin: 0 10px;
  }
}


@media screen and (max-width: 600px) {
  .contacts__content {
   flex-direction: column-reverse;
   
  }
}


.contacts__column {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

@media screen and (max-width: 750px) {
  .contacts__column {
    padding-top: 30px;
    row-gap: 14px;
  }
}

.contacts__links-wrapper {
  display: flex;
  column-gap: 0px;
}

.contacts__detail-title {
  margin: 0;
  min-width: 200px;
  font-size: 16px;
  padding-bottom: 5px;
  color: #012d49;
  font-weight: 400;
}

.contacts__link {
  color: #012d49;
  text-decoration: none;
  font-size: 24px;
  transition: color 0.4s ease-in-out;
}

@media screen and (max-width: 750px) {
  .contacts__link {
    color: #012d49;
    text-decoration: none;
    font-size: 18px;
    transition: color 0.4s ease-in-out;
  }
}

