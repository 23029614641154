.notfound {
  
}

.notfound-wrapper {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  background-size: cover;
  background-repeat: no-repeat;
 
}

.notfound__status {
  font-weight: 700;
  font-size: 180px;
  line-height: 169px;
  color: #012d49;
  margin: 0;
  margin-top: 100px;
}

@media screen and (max-width: 768px) {
  .notfound__status {
    margin-top: 408px;
  }
}

@media screen and (max-width: 768px) {
  .notfound__status {
    margin-top: 330px;
    font-size: 90px;
    line-height: 97px;
  }
}

.notfound__message {
  font-weight: 400;
  font-size: 24px;
  line-height: 32px;
  color: #012d49;
  margin: 15px 0 184px;
}

@media screen and (max-width: 768px) {
  .notfound__message {
    margin: 10px 0 284px;
    font-size: 12px;
    line-height: 15px;
  }
}

.notfound__link {
  font-size: 16px;
  font-weight: 700;  
  cursor: pointer;
  text-decoration: none;
  width: 120px;
  height: 40px;
  border: none;
  outline: none;
  background-color: #012d49;
  color: white;  
  border-radius: 22px;
  margin: 0;
 display: flex;
 justify-content: center;
 align-items: center;
}


