.header {
  padding: 80px 0;
  width: 100%;
  box-sizing: border-box;
}

@media screen and (max-width: 750px) {
  .header {
    padding: 30px 6px;
    width: 100%;
  }
}

.header__content {
  display: flex;  
  margin: 0 auto;
  align-items: center;
  max-width: 1140px;
}