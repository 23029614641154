.logo {
  width: 124px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s;
}

@media screen and (max-width: 750px) {
  .logo {
    width: 94px;

  }
}

@media screen and (max-width: 450px) {
  .logo {
    width: 64px;

  }
}


.logo:hover {
  opacity: 0.6;
}
