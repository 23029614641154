.popup {
    display: flex;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.1);
    justify-content: center;
    align-items: center;
    visibility: hidden;
    opacity: 0;
 
    z-index: 6;
  }

  .popup__image-container {
        
    position: relative;    
  }

  .popup.popup_is-opened {
    visibility: visible;
    opacity: 1;
    transition: visibility 0.5s, opacity 0.4s ease-in;
  }

  .popup__image {
    max-width: 85vw;
    max-height: 85vh;
    pointer-events: none;
  }

  .popup__image-title {
    font-weight: 700;
    font-size: 18px;
   
    color: #FFFFFF;
    padding-top: 20px;
    margin: 0;
    max-width: 650px;
   
  }

.popup__close-button {
    background: url(../../images/popup__close-button.svg);
    border: none;  
    cursor: pointer;
    background-position: center;
    position: absolute; 
    width: 45px;
    height: 45px;  
    top: -40px;
    right: -40px;  
    padding: 0;
    outline: none; 
  }
  
  .popup__close-button:hover {
    background-image: url(../../images/popup__close-button_hover.svg);
    border-color: rgba(255, 255, 255, 0.6);
  }
  
  
  @media screen and (max-width:768px) {
    .popup__close-button {
      right: 0;
      width: 21px;
      height: 21px;    
    }
  }

  .image-popup {
    background: rgba(1, 45, 73, 0.8);
  }