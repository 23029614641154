

.navigation__menu {
  display: flex;
  justify-content: flex-end;
  height: 44px;
  z-index: 5;
  align-items: center;
  width: 100%;
}

.navigation__menu_type_header {
  
  padding-left: 0;
  align-self: center;
}


.navigation__list {
  list-style-type: none;
  margin: 0;
  display: flex;
  align-items: center;

}

.navigation__item:not(:first-of-type) {
  margin-left: 40px;
}

.navigation__item {
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media screen and (max-width: 750px) {
  .navigation__item {
    font-size: 14px;

  }
}

.navigation__link {
  text-decoration: none;
  margin: 0;
  color: #012d49;
  transition: all 0.4s ease-in-out;
}


.navigation__link_type_contact {
  color: #fff;
}


.navigation__item_type_contact {
  width: 160px;
  height: 40px;
  border: none;
  outline: none;
  background-color: #012d49;
  color: white;  
  border-radius: 22px;
  border: 2px solid #012d49;
  transition: all 0.4s ease-in-out;
  box-sizing: border-box;
}

@media screen and (max-width: 750px) {
  .navigation__item_type_contact {
    width: 140px;
    height: 30px;
  }
}

@media screen and (max-width: 750px) {
  .navigation__list {
    padding-left: 20px;
  }
}


.navigation__item_type_contact:hover {
  background-color: transparent;
  cursor: pointer;
}


.navigation__item_type_contact:hover .navigation__link_type_contact {
  color: #012d49;
}

.navigation__link:hover {
 opacity: .8;
}